<template>
  <b-container fluid>
    <b-alert show v-if="state === 0"
      ><b-icon
        icon="circle-fill"
        animation="throb"
        font-scale="1.5"
        class="mr-2"
      ></b-icon
      >Loading, please wait</b-alert
    >
    <b-alert variant="success" show v-else-if="state === 1"
      ><b-icon
        icon="circle-fill"
        animation="throb"
        font-scale="1.5"
        class="mr-2"
      ></b-icon
      >Registration confirmed! Redirecting to login page...</b-alert
    >
    <b-alert variant="danger" show v-else-if="state === 2"
      >Could not confirm registration</b-alert
    >
  </b-container>
</template>

<script>
import { confirm_registration } from "@/apis/auth.js";
export default {
  name: "ConfirmRegistration",
  data() {
    return {
      state: 0, // 0: loading, 1: success, 2: failure
    };
  },
  computed: {
    token() {
      return this.$route.params.token;
    },
  },
  created() {
    this.token &&
      confirm_registration(this.token)
        .then(() => {
          this.state = 1;
          setTimeout(() => this.$router.push("/signIn"), 3000);
        })
        .catch(() => {
          this.state = 2;
        });
  },
};
</script>

<style scoped></style>
